import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import CandyPantsLogo from "../images/candypants.svg"
import CandyPantsIcon from "../images/candypants-icon.svg"
import Fade from "react-reveal/Fade"

const CandyPantsCS = props => (
    <div className="container-fluid container-xxl">
        <div className="row pt-md-5">
            <div className="col-12 col-md-4 pb-1 mb-4   candypants-text d-flex flex-column justify-content-center">
                <div className=" ">
                    <img src={CandyPantsIcon} className="overlay-cpi0" alt="Anchor vector" />
                    <div className="pt-5 mb-3">
                        <span className="pre-title">WHO ARE</span>
                    </div>
                    <div className="mb-3">
                        <img src={CandyPantsLogo} width={247} alt="CANDYPANTS" />
                    </div>
                    <p className="sub-title-purple">Maliblue Yachts in partnership with CANDYPANTS.</p>
                    <p className="mb-3">
                        Remixing the rules of global partying, Candypants are the beat behind some of the world’s wildest days and
                        nights. With events across the globe, the aim is to offer customers a partying experience unlike any other.
                    </p>
                    <div>
                        <a className="button fill" href="https://candypants.events/" target="_blank" rel="noreferrer noopener">
                            <span>discover Candypants</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-8 position-relative pe-0 candypants-image">
                <img src={CandyPantsIcon} className="overlay-cpi1" alt="Anchor vector" />
                <StaticImage width={830} className="candypantsgirls-img" src="../images/image3.png" alt="CandyPants girls" />
                <img src={CandyPantsIcon} className="overlay-cpi2 d-none d-md-block" alt="Anchor vector" />
            </div>
        </div>
    </div>
)

const CandyPants = () => (
    <div className="container-xxl">
        <div className="row">
            <div className="col-12 col-md-5 col-lg-4 pb-4  ps-inside d-flex flex-column justify-content-center order-2 order-md-1">
                <Fade delay={700} duration={1500} cascade>
                    <div className=" ">
                        <img src={CandyPantsIcon} className="overlay-cpi0" alt="Anchor vector" />
                        <div className="pt-5 mb-3">
                            <span className="pre-title">WHO ARE</span>
                        </div>
                        <div className="mb-3">
                            <img src={CandyPantsLogo} width={247} alt="CANDYPANTS" />
                        </div>
                        <p className="sub-title-purple">Maliblue Yachts in partnership with CANDYPANTS.</p>
                        <p className="mb-3">
                            Remixing the rules of global partying, Candypants are the beat behind some of the world’s wildest days and
                            nights. With events across the globe, the aim is to offer customers a partying experience unlike any other.
                        </p>
                        <div>
                            <a className="button fill" href="https://candypants.events/" target="_blank" rel="noreferrer noopener">
                                <span>discover Candypants</span>
                            </a>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="col-12 col-md-7 col-lg-8 pt-4 py-md-5 position-relative pe-0 candypants-image order-1 order-md-2">
                <img src={CandyPantsIcon} className="overlay-cpi1" alt="Anchor vector" />
                <Fade right>
                    <StaticImage width={830} className="candypantsgirls-img" src="../images/image3.png" alt="CandyPants girls" />
                </Fade>
                <img src={CandyPantsIcon} className="overlay-cpi2 d-none d-md-block" alt="Anchor vector" />
            </div>
        </div>
    </div>
)

export { CandyPantsCS, CandyPants }
